@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Roboto:wght@300;400&display=swap');

.main_header{
    background: linear-gradient(to right, #FFC700, #FD7900);
}
.container{
    padding-top: 13px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px 45px;
}
.navbar-expand-md .navbar-nav 

.text{
    display: flex;
    flex-direction: column;
    max-width: 573px;
    width: 100%;
    padding: 24px;
}
.buttons{
    display: flex;
    gap: 30px;
}

.pretitle h1{
    font-size: 60px;
    color: #ffffff;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    text-shadow:2px 3px 1px #E84C1F;
}
.title p{
    font-family: "Roboto", sans-serif; 
    font-size: 22px;
    color:#ffffff;
    line-height: 28px;
}
.buttons a{
    width: 220px;
    height: 59px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 44px;
    transition: all 0.8s ease; 
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
}
.sec_button{
    background-color: #CC30AA;
    color: #ffffff;
    transition: all 0.3s ease;
}
.sec_button:hover{
    background: linear-gradient(to right, #5D074A, #CC30AA);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.first_button{
    color: white;
    border: 1px solid #ffffff;
}
.first_button:hover{
background-color: white;
color: #FFB501;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.mobile_hero{
    display: none;
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

@media all and (max-width:1090px){
    .hero img{
       width: 407px;
       height: 427px;
    }
    .pretitle h1{
        font-size: 35px;
    }
    .main_header{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        height: 480px;
    }
    .container{
        align-items: center;
    }
}

@media all and (max-width:820px){
    .main_header{
        height: 396px;
    }
    .hero img{
        width: 307px;
        height: 323px;
    }
    .pretitle h1{
        font-size: 45px;
    }
    .title p{
        font-size: 18px;
    }
    .buttons a{
        width: 180px;
        height: 40px;
        padding: 10px;
    }
}
@media all and (max-width:770px){
    .container{
        gap: 0px;
    }
    .main_header{
        height: 100%;
        justify-content: center;
    }
    .text{
        display: flex;
        flex-direction: column;
        max-width: 380px;
        width: 100%;
        padding: 24px;
    }
    .buttons a{
        font-size: 14px;
        text-align: center;
    }
    .title p{
        font-size: 18px;
        text-align: left;
        margin-top: 20px;
    }
}
@media all and (max-width:690px){
    .container{
        flex-direction: column;
    }
    .main_header{
        height: 100%;
        justify-content: center;
        padding-top: 30px;
    }
    .text{
        text-align: center;
    }
}
@media all and (max-width:450px){
    .main_header{
            background: linear-gradient(to right, #FFC700, #FD7900);
        }
    .buttons{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
    }
    .buttons a{
        width: 330px;
        padding: 24px 50px;
    }
    .hero_web{
        display: none;
    }
    .hero .mobile_hero{
        display: block;
        height: 455px;
        width: 341px;
    }
    .text{
        gap: 15px;
    }
}
