@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&family=Ubuntu:ital,wght@0,300;0,400;1,400&display=swap');

.container{
 padding: 0px 0px 100px 0px;
 margin: 0px 30px;
}
.container h2{
    text-align: center;
    font-size: 42px;
    color: #606060;
    font-family: "Ubuntu", sans-serif;
    padding: 50px 0px 60px;
    font-weight: 400;
}

.image{
    width: 320px;
    height: 450px;
}


@media all and (max-width:970px){
    .slide .image {
        height: 420px;
    }
}
@media all and (max-width:700px){
    .image{
        width: 300px;
    }
}
@media all and (max-width:450px){
    .container h2{
        font-size: 30px;
    }
}