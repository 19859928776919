@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&family=Ubuntu:ital,wght@0,300;0,400;1,400&display=swap');


.container{
    background-color: #FAFAFA;
    padding: 30px 0px 100px;
}
.container h2{
    text-align: center;
    font-size: 42px;
    color: #606060;
    font-family: "Ubuntu", sans-serif;
    padding: 50px 0px 60px;
    font-weight: 400;
}

.about_main{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.about_text_title{
    width: 550px;
}
.about_text_title p{
    font-size: 22px;
    font-family: "Roboto", sans-serif; 
    color: #354052;

}

.about_text_title p span{
    font-size: 28px;
    font-weight: 700;
}
.hero{
    width: 450px;
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.about_text_pretitle p{
    font-size: 22px;
    font-family: "Roboto", sans-serif; 
}


@media all and (max-width:820px){
    .container h2{
        font-size: 35px;
        padding: 50px 0px 30px;
    }
    .about_main{
        margin: 0px 30px;
    }
    .about_text_title{
        width: 300px;
    }
    .about_text_title p{
        font-size: 18px;
    }
    .hero{
        width: 280px;
    }

}
@media all and (max-width:650px){
    .about_main{
        flex-direction: column-reverse;
    }
    .about_text_title{
        max-width: 500px;
        width: 100%;
        text-align: justify;
    }
}
@media all and (max-width:450px){
    .container h2{
        font-size: 30px;
        padding: 10px;
    }
    .about_text_title p span{
        font-size: 20px
    }
    .about_text_title p{
        font-size: 18px;
    }
    .hero{
        width: 360px;
    }
    
}