.footer_container{
    background-color: #501D44;
    padding: 50px 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.icon_contact{
    background-color: #501D44;
}
.footer_box a{
    color: white;
    font-size: 30px;
    margin-left: 30px;
    transition: all 1s ease;
}
.footer_box a:hover{
    color: #FFC700;
    text-shadow: 1px 1px 1px white;
}
.developing a{
    color: rgb(167, 167, 167);
    transition: all 1s ease;
}
.developing a:hover{
    color: #FFC700;
}

@media all and (max-width: 450px){
    .footer_container{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
    }

}