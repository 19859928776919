.fixed_container_icon button{
    color: rgb(255, 255, 255);
    font-size: 30px;
    background-color: #FD7900;
    padding: 10px 16px;
    border-radius: 50%;
    border: none;
    position: fixed;
    bottom: 65px;
    right: 44px;
    z-index: 100;
    box-shadow: 5px 5px 3px rgb(91, 61, 9);
    transition: all 2s ease;
}
.fixed_container_icon button:hover{
    background-color: #8e541e;
}
.fixed_container_icon a{
    color: rgb(255, 255, 255);
    font-size: 30px;
    background-color: #26b8ec;
    padding: 10px 16px;
    border-radius: 50%;
    border: none;
    position: fixed;
    bottom: 150px;
    right: 44px;
    z-index: 100;
    box-shadow: 5px 5px 3px rgb(91, 61, 9);
    transition: all 2s ease;
}

.fixed_container_icon a:hover{
    background-color: #165a73;
}