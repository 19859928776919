
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&family=Ubuntu:ital,wght@0,300;0,400;1,400&display=swap');

.container{
    width: 70%;
    margin: 0 auto;
    padding: 45px 0px 50px;
}
.container h2{
    text-align: center;
    font-size: 42px;
    color: #606060;
    font-family: "Ubuntu", sans-serif;
    padding-top: 40px;
    font-weight: 400;
}


.feedback_container{
    margin-bottom: 15px;
    margin-top: 50px;
}
.feedback_container img{
    border-radius: 10px;
    border: 5px solid #ffc107;
    padding: 5px;

}

@media all and (max-width: 450px){
    .container h2{
        font-size: 30px;
    }
}