@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&family=Ubuntu:ital,wght@0,300;0,400;1,400&display=swap');

.tertiary {
    background-color:white;
}
.href{
    color: #606060;
    transition: all 0.5s ease;
    cursor: pointer;
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    transition: all 0.5s ease;
    cursor: pointer;
    position: relative;
    margin-right: 20px;
    text-decoration: none;
}
.href::after {
    content: ''; 
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 1px;
    background-color: transparent; 
    transition: all 0.3s ease; 
}

.href:hover::after,
.href:active::after {
    background-color: #FFAB71; 
}

.logo{
    color: #252b42d5;
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
    font-size: 24px;
    text-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
}
.logo:hover{
    color: #96BB7C;
}


.container{
    max-width: 1243px;
    width: 100%;
}
.button{
    border: none;
}
.button:hover,
.button:active,
.button:focus{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}

.button:hover:not(:focus) {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
    outline: none !important;
}
@media all and (max-width:840px){
    .container {
        margin: 0px 30px;
}
}
@media all and (max-width:770px){
    .logo{
        width: 190px;
    }
}
@media all and (max-width:450px){

    .tertiary {
        padding: 20px 0px;
        margin: 0px;
    }
    .tertiary .container{
        z-index: 100;
        background-color: white;
    }
    .button{
        z-index: 100;
    }
 
}