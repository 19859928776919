
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&family=Ubuntu:ital,wght@0,300;0,400;1,400&display=swap');

.benefits_main{
    padding:50px 10px 140px;
    background: linear-gradient(to right, #F15886, #A625D4);
    position: relative;
    height: 100%;
}

.benefits_main h2{
    text-align: center;
    font-size: 42px;
    color: white;
    font-family: "Ubuntu", sans-serif;
    padding: 50px 0px 60px;
    font-weight: 400;
    
}

.benefits_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 55px;
}
.benefit_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
    padding: 10px;
    font-family: "Roboto", sans-serif;
    width: 180px;
    height: 285px;
    color: white;
    border-radius: 20px;
    font-size: 16px;
    border: 1px solid white;
}
.icon{
    width: 51px;
    height: 51px;
}
.icon_top{
    position: absolute;
    top: -10%;
    left: 10%;
}
.icon_bottom{
    position: absolute;
    bottom:-10%;
    right:10%;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

@media all and (max-width:820px){
    .benefits_main h2{
        font-size: 35px;
    }
    .benefits_container{
        gap: 30px;
    }
    .icon_top{
        width: 140px;
        top: -5%;
    }
    .icon_bottom{
        width: 140px;
        bottom: -5%;
    }
}
@media all and (max-width:450px){
    .benefits_container{
        flex-direction: column;
    }
    .icon_top{
        width: 160px;
        top: -3%;
    }
    .icon_bottom{
        width: 160px;
        bottom: -3%;
    }
    .benefits_main h2{
        font-size: 30px;
        width: 250px;
        margin:0 auto;
        padding: 18px 0px 48px 0px;
    }
    .benefit_item{
        flex-direction: row;
        width: 330px;
        height: 150px;
    }
}