
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&family=Ubuntu:ital,wght@0,300;0,400;1,400&display=swap');

.program_container{
    background: linear-gradient(to right, #FFC700, #FD7900);
    padding: 60px 0px;
}
.container h2{
    text-align: center;
    font-size: 42px;
    color: #ffffff;
    font-family: "Ubuntu", sans-serif;
    padding-bottom:30px;
    font-weight: 400;
}

.accordion{
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
}
.accordion .item{
    font-family: "Ubuntu", sans-serif;
    border: 0;
    border-radius: 7px;
    padding: 5px;
    margin-bottom: 7px;
    font-size: 18px;
}
.accordion .item  button{
    font-family: "Ubuntu", sans-serif;
    font-size: 19px;
    color: #252B42;
    font-weight: 700;
    background-color: transparent;
    box-shadow: none;
    transition: all 1s ease;

}

.accordion .item button:active::after, 
.accordion .item button:focus::after{
    border-radius: 50%; 
    transition: all 1s ease;
}
.accordion .item .accordion-collapse{
    transition: all .4s ease;
}


@media all and (max-width: 770px){
    .accordion{
        max-width: 650px;
    }
}

@media all and (max-width: 650px){
    .accordion{
        max-width: 520px;
    }
}

@media all and (max-width: 450px){
.container h2{
    font-size: 30px;
}
.accordion{
    max-width: 300px;
}
}